<template>
  <page-header-wrapper>
    <a-card :bordered="false">

      <div class="table-operator">
        <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
      </div>
      <s-table
        ref="table"
        size="default"
        :rowKey="(record) => record.id"
        :columns="columns"
        :data="loadData"
      >
        <span slot="action" slot-scope="text, record">
          <template>
            <router-link :to="`/web/invite/` + record.id">查看邀请码</router-link>
            <a-divider type="vertical" />
            <!--            <a-popconfirm-->
            <!--              title="你确定要停用吗?"-->
            <!--              ok-text="Yes"-->
            <!--              cancel-text="No"-->
            <!--              @confirm="handleDelete(record)"-->
            <!--            >-->
            <!--              <a href="#">停用</a>-->
            <!--            </a-popconfirm>-->
          </template>
        </span>
      </s-table>

      <invite-code-create-form
        ref="createModal"
        :visible="formVisible"
        :loading="confirmLoading"
        :model="formModel"
        @cancel="handleCancel"
        @ok="handleSave"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { STable } from '@/components'
import { createInviteGroup, getInviteGroupList } from '@/api/web'
import InviteCodeCreateForm from '@/views/web/modules/InviteCodeCreateForm'

export default {
  name: 'InviteCodeGroupList',
  components: {
    STable,
    InviteCodeCreateForm
  },
  data () {
    return {
      formVisible: false,
      confirmLoading: false,
      formModel: null,
      queryParam: {},
      loadData: parameter => {
        return getInviteGroupList(Object.assign(parameter, this.queryParam))
      },
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          sorter: true
        },
        {
          title: '名称',
          dataIndex: 'name',
          sorter: true
        },
        {
          title: '创建用户',
          dataIndex: 'creatorId',
          sorter: true
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          sorter: true
        },
        {
          title: '数量',
          dataIndex: 'codes',
          customRender: (record) => record.length
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ]
    }
  },
  methods: {
    handleAdd () {
      this.$refs.createModal.form.resetFields()
      this.formModel = null
      this.formVisible = true
    },
    handleSave () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          createInviteGroup(values).then(() => {
            this.formVisible = false
            this.confirmLoading = false
            form.resetFields()
            this.$refs.table.refresh()
            this.$message.success('创建')
          }).catch(() => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCancel () {
      this.formVisible = false
    }
  }
}
</script>

<style scoped>

</style>
